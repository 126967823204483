import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <header id="home">
      <div className="container header__container">
        <h5>{t('hello')}</h5>
        <h1>Darwin Ramirez</h1>
        <h4 className="text-light">{t('dev')}</h4>
        <CTA />
        <div>
          <a href="#contact" className="scroll__down">
            {t('Scroll')}
          </a>
          <HeaderSocials />
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import CV_es from '../../assets/CV_ES_Darwin_Ramirez.pdf';
import CV_en from '../../assets/CV_EN_Darwin_Ramirez.pdf';

import { useTranslation } from 'react-i18next';

const CTA = () => {

  const { t, i18n  } = useTranslation();

  const currentLanguage = i18n.language;

  const CV = currentLanguage === 'es' ? CV_es : CV_en;

  return (
    <div className="cta">
      <a href={CV} download className="btn">
      {t('Download')}
      </a>
      <a href="#contact" className="btn btn-primary">
        {t('Talk')}
      </a>
    </div>
  );
};

export default CTA;

import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { BsWordpress } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
/* import { FaAngellist } from 'react-icons/fa' */

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/darwinramirez92/" target="_blank" aria-label="link to linkedin" rel="noreferrer" ><BsLinkedin /></a>
      <a href="https://github.com/dercdevil" target="_blank" aria-label="link to github" rel="noreferrer" ><FaGithub /></a>
      <a href="https://elnomadad.com/" target="_blank" aria-label="link to Wordpress" rel="noreferrer" ><BsWordpress /></a>
      {/* <a href="https://angel.co/u/meri-gogichashvili" target="_blank" rel="noreferrer" ><FaAngellist /></a> */}
    </div>
  )
}

export default HeaderSocials
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        greeting: 'Hello I\'m',
        hello: 'Hello I\'m',
        dev: 'Full-stack Developer',
        Scroll: 'Scroll Down',
        know: 'Get to know',
        about: 'About Me',
        Experience: 'Experience',
        year: 'year',
        Projects:'Projects',
        Projectsc: '40+ Completed Projects',
        description: 'I have had the opportunity to work with different projects that help to see the world in many ways, projects from IoT, blockchain, wordpress, frontend, API, mobile, but I really like more the backend projects. Also passionate about innovative and scalable projects, always ready to accept different challenges and solve them in the most efficient way possible.',
        Talk: 'Let\'s Talk',
        Download : 'Download CV',
        skillh: 'The Skills I Have',
        skills: 'Skills',
        front: 'Front-end Development',
        back: 'Back-end Development',
        contact: 'Get In Touch',
        textcontact: 'I do receive your messages and will respond asap if the valid email is provided :)',
        Send: 'Send a message',
        name:'Your Full Name',
        email:'Your Email',
        message: 'Your message',
        home: 'Home',
        aboutf: 'About',
        contactf: 'contact',
        rights:'All rights reserved.'
      },
    },
    es: {
      translation: {
        greeting: 'Hola Yo Soy',
        hello: 'Hola Yo Soy',
        dev: 'Desarrollador Full-Stack',
        Scroll: 'Desplazarse abajo',
        know: 'Conozca',
        about: 'Acerca de mi',
        Experience: 'Experiencia',
        year: 'años',
        Projects:'Proyectos',
        Projectsc: '+40 Proyectos Completados',
        description: 'He tenido la oportunidad de trabajar con diferentes proyectos que ayudan a ver el mundo de muchas maneras, proyectos desde IoT, blockchain, wordpress, frontend, API, mobile, pero realmente me gustan mas los proyectos de backend. Ademas apasionado de los proyectos innovadores y escalables, siempre dispuesto a aceptar diferentes retos y resolverlos de la manera más eficiente posible.',
        Talk: 'Hablemos',
        Download : 'Descargar CV',
        skillh: 'Las competencias que tengo',
        skills: 'Habilidades',
        front: 'Desarrollo Front-end',
        back: 'Desarrollo Back-end',
        contact: 'Contacto',
        textcontact: 'Recibo sus mensajes y responderé lo antes posible si se proporciona el correo electrónico válido :)',
        Send: 'Enviar un mensaje',
        name:'Nombre y apellidos',
        email:'Su correo electrónico',
        message: 'Su mensaje',
        home: 'Inicio',
        aboutf: 'Acerca de',
        contactf: 'contacto',
        rights:'Todos los derechos reservados.'
      },
    },
  },
});

export default i18n;
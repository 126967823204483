import React, { Suspense } from 'react';
import Header from '../components/header/Header';

const Topbar = React.lazy(()=> import('../components/topbar/Topbar'));
const Intro = React.lazy(()=> import('../components/intro/Intro'));
const Experience = React.lazy(()=> import('../components/experience/Experience'));
const Contact = React.lazy(()=> import('../components/contact/Contact'));
const Footer = React.lazy(()=> import('../components/footer/Footer'));


const App = () => {
  return (
    <>
      <Header />
      <Suspense fallback={<div style={{textAlign:'center',padding:"40px"}}>Loading</div>}>
        <Topbar />
        <Intro />
        <Experience />
        {/* <Portfolio /> */}
        {/* <Testimonials /> */}
        <Contact />	
	    </Suspense>
      <Footer />
    </>
  )
}

export default App

import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppRouter } from './routers'


const App = () => {
  return (
    <>
      <GoogleOAuthProvider clientId='274526874135-ms3rj99otq0miple59f2ovvp3u0nvbq2.apps.googleusercontent.com'>
        <AppRouter/>
      </GoogleOAuthProvider>
    </>
  )
}

export default App

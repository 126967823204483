import React/* , { lazy }  */from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from '../pages/home'
/* const Skills = lazy(() => import('../pages/skills')) */



export const AppRouter = () => {
    return (
    <BrowserRouter>
      <Routes>

        <Route index element={<Home />} />
        {/* <Route path="skills" element={<Skills/>} /> */}
        <Route path="*" element={<Home />} />

      </Routes>
    </BrowserRouter>
    )
  }